import moment from 'moment';
import { Text, Input, HStack, Flex, Select, Button, Highlight, Spinner, Avatar, Center, Image } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { User as UserAuth } from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { User } from '../models/User';
import { auth } from '../config/firebase';
import { CurrentUserContext } from '../contexts/CurrentUserProvider';
import LoadingScreen from '../components/LoadingScreen';
import { WardrobeItem } from '../models/WardrobeItem';
import { fetchWardrobeItemToReclassify, updateWardrobeItem } from '../services/WardrobeItemsService';
import { MultiSelect, Option } from 'chakra-multiselect';
import { STYLES_LIST } from '../models/StylesList';

function ReclassifyScreen() {
  const { currentUser } = useContext(CurrentUserContext);
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);
  const [item, setItem] = useState<WardrobeItem>();
  const [styleSelections, setStyleSelections] = useState<any[]>([]);

  const getNextUnclassifiedItem = async () => {
    await fetchWardrobeItemToReclassify(user as UserAuth)
        .then((res) => res.json())
        .then((result) => {
            if (!result.seasonality) result.seasonality = "all-weather";
            if (result.styles) {
                setStyleSelections(result.styles.map((lbl: string) => ({ label: lbl, value: lbl })));
            }
            console.log('result: ', result);
            setItem(result);
        });
  }

  const handleSaveAndNext = async () => {
    if (!user) return;
    if (!item) return;
    if (styleSelections)
        item.styles = styleSelections.map((s) => s.value);
    console.log('item: ', item);
    await updateWardrobeItem(user, item).then((res) => {
        console.log('updated:', res);
    });
    await getNextUnclassifiedItem().then(() => console.log('next item got'));
  }

  useEffect(() => {
    if (!user) return;
    console.log('Reclassify.useEffoect');
    getNextUnclassifiedItem().catch(console.error);
  }, [user]);

  if (!user || !item) return <LoadingScreen />;

  return (
    <Flex flexDirection="column" height="full" width="100%" flex={1} px={12} py={8} alignItems="center">
        <Text variant="header">Reclassify Wardrobe Item</Text>
        <Flex height="full" minWidth="50%" flexDirection="row" mt={8}>
            <Flex flexDirection="column" borderWidth={0.5} mx={8} p={4}>
                <Image src={item.url} />
                <Text my={4}>{item.userid}</Text>
                <Text>ItemId: {item.id}</Text>
            </Flex>
            <Flex height="full" flexDirection="column" borderWidth={0.5} mx={8} p={4}>
                <Text mb={1} color="orange.400">Edit Details:</Text>
                <Flex flex={2} flexDir="column" mr={4}>
                    <Text fontSize="xs">Primary Category</Text>
                    {/* <Input value={item.primarycategory} onChange={(e) => setItem({...item, primarycategory: e.target.value})} /> */}
                    <Select variant="flushed" value={item.primarycategory} onChange={(e) => setItem({ ...item, primarycategory: e.target.value } as WardrobeItem)}>
                        <option value="undetermined">undetermined</option>
                        <option value="tops">Tops</option>
                        <option value="bottoms">Bottoms</option>
                        <option value="dress">Dress</option>
                        <option value="shoes">Shoes</option>
                        <option value="accessories">Accessories</option>
                    </Select>
                </Flex>
                <Flex flex={2} flexDir="column" mt={4}>
                    <Text fontSize="xs">Sub Category</Text>
                    <Select variant="flushed" value={item.subcategory} onChange={(e) => setItem({ ...item, subcategory: e.target.value } as WardrobeItem)}>
                        <option value="">Unknown</option>
                        <option value="Belts">Belts</option>
                        <option value="Capris">Capris</option>
                        <option value="Dresses">Dresses</option>
                        <option value="Gloves">Gloves</option>
                        <option value="Hat">Hat</option>
                        <option value="Heels">Heels</option>
                        <option value="Innerwear Vests">Innerwear Vests</option>
                        <option value="Jackets">Jackets</option>
                        <option value="Jeans">Jeans</option>
                        <option value="Jeggings">Jeggings</option>
                        <option value="Jumpsuit">Jumpsuit</option>
                        <option value="Leggings">Leggings</option>
                        <option value="Pants">Pants</option>
                        <option value="Rompers">Rompers</option>
                        <option value="Sandals">Sandals</option>
                        <option value="Scarves">Scarves</option>
                        <option value="Shirts">Shirts</option>
                        <option value="Shoes">Shoes</option>
                        <option value="Shorts">Shorts</option>
                        <option value="Shrug">Shrug</option>
                        <option value="Skirts">Skirts</option>
                        <option value="Stockings">Stockings</option>
                        <option value="Sweaters">Sweaters</option>
                        <option value="Sweatshirts">Sweatshirts</option>
                        <option value="Tops">Tops</option>
                        <option value="Track Pants">Track Pants</option>
                        <option value="Tracksuits">Tracksuits</option>
                        <option value="Tshirts">Tshirts</option>
                        <option value="Tunics">Tunics</option>
                        <option value="Waistcoat">Waistcoat</option>
                    </Select>
                </Flex>
                <Flex flex={2} flexDir="column" mt={4}>
                    <Text fontSize="xs">Main Color</Text>
                    <Select variant="flushed" value={item.maincolor} onChange={(e) => setItem({ ...item, maincolor: e.target.value } as WardrobeItem)}>
                        <option value="">Unknown</option>
                        <option value="Navy Blue">Navy Blue</option>
                        <option value="Blue">Blue</option>
                        <option value="Black">Black</option>
                        <option value="Grey">Grey</option>
                        <option value="Brown">Brown</option>
                        <option value="Tan">Tan</option>
                        <option value="Beige">Beige</option>
                        <option value="Green">Green</option>
                        <option value="White">White</option>
                        <option value="Red">Red</option>
                        <option value="Silver">Silver</option>
                        <option value="Olive">Olive</option>
                        <option value="Pink">Pink</option>
                        <option value="Cream">Cream</option>
                        <option value="Off White">Off White</option>
                        <option value="Khaki">Khaki</option>
                        <option value="Purple">Purple</option>
                        <option value="Teal">Teal</option>
                        <option value="Charcoal">Charcoal</option>
                        <option value="Mustard">Mustard</option>
                        <option value="Gold">Gold</option>
                        <option value="Bronze">Bronze</option>
                        <option value="Orange">Orange</option>
                        <option value="Multi">Multi</option>
                        <option value="Yellow">Yellow</option>
                        <option value="Mauve">Mauve</option>
                        <option value="Peach">Peach</option>
                        <option value="Maroon">Maroon</option>
                        <option value="Grey Melange">Grey Melange</option>
                        <option value="Turquoise Blue">Turquoise Blue</option>
                        <option value="Skin">Skin</option>
                        <option value="Burgundy">Burgundy</option>
                        <option value="Lavender">Lavender</option>
                        <option value="Magenta">Magenta</option>
                        <option value="Rust">Rust</option>
                        <option value="Coffee Brown">Coffee Brown</option>
                        <option value="Sea Green">Sea Green</option>
                        <option value="Copper">Copper</option>
                        <option value="Taupe">Taupe</option>
                        <option value="Nude">Nude</option>
                        <option value="Mushroom Brown">Mushroom Brown</option>
                        <option value="Metallic">Metallic</option>
                        <option value="Fluorescent Green">Fluorescent Green</option>
                        <option value="Lime Green">Lime Green</option>
                    </Select>
                </Flex>
                <Flex flex={2} flexDir="column" mt={4}>
                    <Text fontSize="xs">Seasonality</Text>
                    <Select variant="flushed" value={item.seasonality} onChange={(e) => setItem({ ...item, seasonality: e.target.value } as WardrobeItem)}>
                        <option value="all-weather">All Weather</option>
                        <option value="hot-weather">Hot Weather</option>
                        <option value="cold-weather">Cold Weather</option>
                    </Select>
                </Flex>
                <Flex flex={2} flexDir="column" mt={2}>
                    <Text fontSize="xs">Styles</Text>
                    <MultiSelect
                        multiple
                        options={STYLES_LIST.map((lbl) => ({ label: lbl, value: lbl }))}
                        value={styleSelections || undefined}
                        onChange={(e) => {
                            // console.log('styls: ', e);
                            // setItem({...item, styles: e as string[]});
                            // updateStyles(e as string[]);
                            setStyleSelections(e as string[]);
                        }}
                    />
                </Flex>
                <Flex flex={2} flexDir="column" mt={4}>
                    <Text fontSize="xs">Formality</Text>
                    <Select variant="flushed" value={item.formalitylabel} onChange={(e) => setItem({ ...item, formalitylabel: e.target.value } as WardrobeItem)}>
                        <option value="None">None</option>
                        <option value="Casual">Casual</option>
                        <option value="Formal">Formal</option>
                        <option value="Smart Casual">Smart Casual</option>
                        <option value="Sports">Sports</option>
                        <option value="Ethnic">Ethnic</option>
                        <option value="Party">Party</option>
                        <option value="Travel">Travel</option>
                    </Select>
                </Flex>
            </Flex>
        </Flex>
        <Flex flex={1} minWidth="50%" flexDirection="row" mt={8} justifyContent="center">
            <Button variant="solid" onClick={handleSaveAndNext}>Save & Next</Button>
        </Flex>
    </Flex>
  );
}

export default ReclassifyScreen;
