import moment from 'moment';
import { Box, Text, Heading, HStack, Flex, Select, Button, Highlight, Spinner, Avatar, Center, useToast, Link } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { User as UserAuth } from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { CloseIcon } from '@chakra-ui/icons';
import { User } from '../models/User';
import { auth } from '../config/firebase';
import { CurrentUserContext } from '../contexts/CurrentUserProvider';
import LoadingScreen from '../components/LoadingScreen';

function HomeScreen() {
  const { currentUser } = useContext(CurrentUserContext);
  const navigate = useNavigate();
  const toast = useToast();
  const [user, loading, error] = useAuthState(auth);

  if (!user) return <LoadingScreen />;

  return (
    <Flex flexDirection="column" height="100%" flex={1} px={12} py={8}>
        <Text>Welcome Home</Text>
        <Text as="u" onClick={() => navigate("reclassify")}>Reclassify</Text>
        <Text as="u" onClick={() => navigate("applications")}>Applications</Text>
    </Flex>
  );
}

export default HomeScreen;
