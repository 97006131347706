import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, Link, useLocation } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Box, Flex, Slide, VStack, Button } from '@chakra-ui/react';
import { auth } from './config/firebase';
import { CurrentUserContext } from './contexts/CurrentUserProvider';
import Header from './components/Header';
import LeftNav from './components/LeftNav';
import LoginScreen from './screens/Login';
import HomeScreen from './screens/Home';
import ReclassifyScreen from './screens/Reclassify';
import ApplicationsScreen from './screens/Applications';

function PageLayout() {
  const [user, loading, error] = useAuthState(auth);
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext);
  const location = useLocation();
  // const pathsThatHideLefNav = ['/clientwardrobe', '/messages', '/clientdetails', '/application', '/invoice'];
  // const [hideLeftNav, setHideLeftNav] = useState(pathsThatHideLefNav.includes(location.pathname));
  const [hideLeftNav, setHideLeftNav] = useState(true);

  // useEffect(() => {
  //   setHideLeftNav(pathsThatHideLefNav.includes(location.pathname));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location]);

  console.log('currentUser: ', currentUser);

  return (
    <>
      <Header handleHamburgerToggle={() => setHideLeftNav(!hideLeftNav)} />
      <Flex flex={1} height="100%" flexDirection="row">
        {!hideLeftNav && (
          <Flex flex={0.1} h="100%" backgroundColor="blue.400">
            <LeftNav />
          </Flex>
        )}
        <Flex flex={hideLeftNav ? 1 : 0.9} alignItems="flex-start">
          <Routes>
            {user ? (
              <>
                {currentUser && (
                  <>
                    <Route path="/login" element={<LoginScreen />} />
                    <Route path="/home" element={<HomeScreen />} />
                    <Route path="/reclassify" element={<ReclassifyScreen />} />
                    <Route path="/applications" element={<ApplicationsScreen />} />
                    <Route path="/" element={<HomeScreen />} />
                  </>
                )}
              </>
            ) : (
              <>
                {/* <Route path='/home' element={<HomeScreen />} /> */}
                <Route path="/login" element={<LoginScreen />} />
              </>
            )}
          </Routes>
        </Flex>
      </Flex>
    </>
  );
}

export default PageLayout;
